<template>
  <b-card
    class="attachment-edit-wrapper"
  >
    <!-- form -->
    <b-form id="attachmentForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="公司ID"
            label-for="company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="company_id"
              size="sm"
              v-model="attachment.company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="文件名"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="attachment.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="原文件名"
            label-for="file_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="file_name"
              size="sm"
              v-model="attachment.file_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="缩微图"
            label-for="thumb"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="thumb"
              size="sm"
              v-model="attachment.thumb"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="文件夹"
            label-for="folder"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="folder"
              size="sm"
              v-model="attachment.folder"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="文件大小"
            label-for="file_size"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="file_size"
              size="sm"
              v-model="attachment.file_size"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="类型 document, image, video, audio"
            label-for="type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="type"
              size="sm"
              v-model="attachment.type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="数据类型，0无 1商品 2公司档案 3采购合同 4销售合同 5库存单据"
            label-for="object_type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="object_type"
              size="sm"
              v-model="attachment.object_type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="数据ID"
            label-for="object_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="object_id"
              size="sm"
              v-model="attachment.object_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="attachment.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="attachment.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="attachment.creator"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col
            cols="12"
            class="mt-50 inline"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="文件A上传"
              label-for="sample_attachment"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="attachment.attachment_id" :theme="'files'"
                :attachment_id="'sample_attachment_a'"
                :id="attachment.attachment_id"
                :object_type="'attachment'"
                :object_id="attachment.attachment_id"
                @change="onUploaded"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            class="mt-50 inline"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="文件B上传"
              label-for="sample_attachment"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="attachment.attachment_id" :theme="'image'"
                :attachment_id="'sample_attachment_b'"
                :id="attachment.attachment_id"
                :object_type="'attachment'"
                :object_id="attachment.attachment_id"
                @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import attachmentStore from './attachmentStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import AttachmentUpload from './AttachmentUpload.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      attachment: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('attachment')) store.registerModule('attachment', attachmentStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('attachment')) store.unregisterModule('attachment')
    })

    const edit = function() {
      store.dispatch('attachment/edit', {id: this.id}).then(res => {
        this.attachment = res.data.data
      })
    }

    const view = function() {
      this.edit()
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('attachment/save', this.attachment).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-attachment-list'});
      })
    }

    const onUploaded = function(id, attachment_list,a) {
      console.log('onUploaded', id, attachment_list,a)
    }

    return {
      edit,
      view,
      cancel,
      save,
      onUploaded,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>